import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import axios from "axios";

// Function to format dates
const formatDate = (date) => {
  if (!date) return "Invalid Date";
  const options = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};

const Charts = ({ itemid, outletName }) => {
  const [itemList, setItemList] = useState([]);
  const [chartRate, setChartRate] = useState([]);
  const [chartReceivedTime, setChartReceivedTime] = useState([]);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (itemid && outletName) {
      axios
        .get(
          `${baseUrl}/reqitemhistory/?item_id=${itemid}&outlet_name=${outletName}&limit=10`
        )
        .then((response) => {
          const responseData = response.data.intbl_purchaserequisition_contract || [];
          
          // Reverse the order of data fetched (from last to first)
          const reversedData = responseData.reverse(); // Reverse data to show from last to first
          setItemList(reversedData); // Set the reversed data to itemList
        })
        .catch((error) => {
          console.error("Error fetching item list:", error);
          setItemList([]); // Fallback to empty array if an error occurs
        });
    }
  }, [itemid, outletName]);

  useEffect(() => {
    if (itemList.length > 0) {
      const arrayRate = [];
      const arrayReceived = [];

      itemList.forEach((item) => {
        if (item.rate && item.ReceivedDate) {
          arrayRate.push(parseFloat(item.rate));
          arrayReceived.push(formatDate(item.ReceivedDate));
        }
      });

      setChartRate(arrayRate);
      setChartReceivedTime(arrayReceived);
    } else {
      // Handle no data scenario
      setChartRate([]);
      setChartReceivedTime([]);
    }
  }, [itemList]);

  return (
    <div className="flex flex-col justify-center items-center h-3/4 w-3/4 md:w-44 md:h-36">
      <div className="md:w-52 md:h-52">
        <Line
          data={{
            labels: chartReceivedTime,
            datasets: [
              {
                label: "Price vs Time Chart",
                backgroundColor: "rgb(255, 99, 132)",
                borderColor: "#94a3b8",
                data: chartRate,
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default Charts;
