import React from "react";
import { useNavigate } from "react-router-dom";
import CostCenter from "./CostCenter";

const Inventorydetailtable = ({ info, Company_Name }) => {
  let navigate = useNavigate();
  function generateTotal(array) {
    let total;
    array.forEach((item) => {
      total = item.Rate * item.UnitsOrdered;
    });
    return total;
  }
  let totalEach = generateTotal(info);
  return (
    <div className="w-full h-full md:h-[500px]">
      {/* overflow-y-scroll overflow-x-auto */}
      <table className="table-auto w-full bg-opacity-75 overflow-auto rounded-2xl text-center relative font-poppins text-sm md:text-base">
        <thead className=" md:h-10 lg:sticky lg:top-0 bg-slate-50">
          <tr className="mt-4 p-4 border-b-2 border-slate-400 md:my-5">
            <th>S.N</th>
            <th>Name</th>
            <th>Group Name</th>
            <th>Vendor Name</th>
            <th>Unit</th>
            <th>UOM</th>
            <th>Rate</th>
            <th>Total</th>
            <th>Tax</th>
          </tr>
        </thead>
        <tbody className="md:h-20 overflow-y-auto">
          {info.map((element, index) => (
            <tr key={index}>
              <td className="">{index + 1}</td>
              <td>{element.Name}</td>
              <td>{element.GroupName}</td>
              <td>{element.Company_Name || Company_Name}</td>
              <td>{element.Count}</td>
              <td>{element.UOM}</td>
              <td>{Math.round(element.Rate)}</td>
              <td> {Math.round(element.Total)}</td>
              <td>{element.Taxable}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Inventorydetailtable;
