import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";
import axios from "axios";
import Navbar from "../Navbar";
import LineChart from "./../Charts/Stats/LineChart";
import "../../scss/performance.scss";
import "../../scss/button.scss";
import SelectSearchInput from "../SelectSearch";
import Footer from "../Footer";
import Yearlychart from "../Charts/Stats/Yearlychart";
import WeeklyChart from "../Charts/Stats/WeeklyChart";

const Performance = () => {
  let url = process.env.REACT_APP_BASE_URL;
  let navigate = useNavigate();

  const [token, setToken] = useState("");
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [listYear, setListYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [dailyTotal, setDailyTotal] = useState([]);
  const [dailyLabel, setDailyLabel] = useState([]);
  const [monthlyLabel, setMonthlyLabel] = useState([]);
  const [monthlyTotal, setMonthlyTotal] = useState([]);
  const [chartShow, setShowChart] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // For handling errors

  useEffect(() => {
    let tokenCheck = localStorage.getItem("token");
    if (!tokenCheck) {
      navigate("/");
    } else {
      setToken(localStorage.getItem("token"));
    }
  }, []);

  useEffect(() => {
    if (selectedOutlet) {
      // Fetch data for the Yearly chart
      axios
        .post(`${url}/compareyearchart`, {
          outlet: `${selectedOutlet}`,
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.data) {
            setLoading(false);
            setData(res.data);
          }
        })
        .catch((error) => {
          setError("Error fetching yearly chart data");
          setLoading(false);
        });
    }
  }, [selectedOutlet]);

  useEffect(() => {
    if (selectedOutlet) {
      // Fetch years for the SelectSearch
      axios
        .post(`${url}/years`, {
          outlet: `${selectedOutlet}`,
          token: localStorage.getItem("token"),
        })
        .then((response) => {
          setListYear(response.data || []); // fallback to empty array if no data
        })
        .catch((error) => {
          setError("Error fetching years data");
          setListYear([]); // fallback to empty array
        });
    }
  }, [selectedOutlet]);

  const showChart = () => {
    setShowChart(true);
    if (selectedOutlet) {
      axios
        .post(`${url}/chartsummary`, {
          outlet: `${selectedOutlet}`,
          date: `${selectedYear}`,
          type: "yearly",
          token: `${token}`,
        })
        .then((res) => {
          if (res?.data) {
            setDailyLabel(res.data?.dailylabel);
            setDailyTotal(res.data?.dailytotal);
            setMonthlyLabel(res.data?.monthlylabel);
            setMonthlyTotal(res.data?.monthlytotal);
          }
        })
        .catch((error) => {
          setError("Error fetching chart summary data");
        });
    }
  };

  return (
    <div>
      <Navbar />
      <div className="search-select-year-outlet">
        <div className="select-outlet-year">
          <SelectSearchInput
            token={token}
            setToken={setToken}
            selectedOutlet={selectedOutlet}
            setSelectedOutlet={setSelectedOutlet}
          />
        </div>
        <div className="select-outlet-year">
          <SelectSearch
            defaultValue={selectedYear}
            search
            placeholder="Select Year"
            onChange={(event) => setSelectedYear(event)}
            options={listYear}
          />
        </div>
        <div className="btn-search-view">
          <button onClick={showChart} className="btn-search">
            Show Chart
          </button>
        </div>
      </div>

      <div className="performance-chart">
        <div className="performance-chart-stats-container">
          {!chartShow ? (
            <>
              {/* Display stats for multiple charts */}
              <div className="flex">
                <div className="performance-chart-stats">
                  <Yearlychart data={data} />
                </div>
                <div className="performance-chart-stats">
                  <WeeklyChart data={data} />
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Display charts when chartShow is true */}
              <div className="flex">
                <div className="performance-chart-stats">
                  <LineChart
                    labels={monthlyLabel}
                    Total={monthlyTotal}
                    labelTitle={"Month"}
                    Title={"Monthly Performance"}
                  />
                </div>
                <div className="performance-chart-stats">
                  <LineChart
                    Total={dailyTotal}
                    labels={dailyLabel}
                    labelTitle={"WeekDay"}
                    Title={"Yearly Weekday Performance"}
                  />
                </div>
              </div>
            </>
          )}

          {/* Error message if data is unavailable */}
          {error && <div className="error-message">{error}</div>}
          {/* Loading state */}
          {loading && !error && (
            <div className="loading-message">Loading...</div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Performance;
