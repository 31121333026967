import axios from "axios";
import React, { useEffect, useState } from "react";

const PurchaseVolTableinfo = ({ PurchaseVolTableinfo }) => {
  // const [PurchaseVolTableinfo, setPurchaseVolTableinfo] = useState([]);
  // const Url = process.env.REACT_APP_BASE_URL;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(`${Url}/salesvspurchase`, {
  //         token: "test",
  //       });

  //       setPurchaseVolTableinfo(response.data.purchaseitembyvolume_responseJson || []);
  //     } catch (error) {
  //       console.error("Error fetching purchase item data:", error);
  //       setPurchaseVolTableinfo([]);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const formatNumber = (number) => {
    return parseFloat(number)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="table-responsive-food -z-0">
      <table className="table-food">
        <thead className="table-background">
          <tr className="sticky-header bg-white">
            <th>Item</th>
            <th>Volume</th>
          </tr>
        </thead>

        {PurchaseVolTableinfo.length > 0 ? (
          PurchaseVolTableinfo.map((item, index) => (
            <tr key={index}>
              <td>{item.Name}</td>
              <td>{formatNumber(item.purchase_total)}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="2">No data available</td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default PurchaseVolTableinfo;
