import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import axios from "axios";

// Function to format dates
const formatDate = (date) => {
  if (!date) return "Invalid Date";
  const options = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};

const BarChart = ({ itemid, outletName }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [itemList, setItemList] = useState([]);
  const [chartUnits, setChartUnits] = useState([]);
  const [chartReceivedTime, setChartReceivedTime] = useState([]);

  useEffect(() => {
    if (itemid && outletName) {
      axios
        .get(
          `${baseUrl}/reqitemhistory/?item_id=${itemid}&outlet_name=${outletName}&limit=10`
        )
        .then((response) => {
          const responseData = response.data.intbl_purchaserequisition_contract || [];
          
          // Reverse the data from last to first (descending order)
          const reversedData = responseData.reverse();
          setItemList(reversedData); // Set reversed data
        })
        .catch((error) => {
          console.error("Error fetching item list:", error);
          setItemList([]); // Fallback to empty array on error
        });
    }
  }, [itemid, outletName]);

  useEffect(() => {
    if (itemList.length > 0) {
      const arrayUnitsOrdered = [];
      const arrayReceived = [];

      itemList.forEach((item) => {
        if (item.UnitsOrdered && item.ReceivedDate) {
          arrayUnitsOrdered.push(parseFloat(item.UnitsOrdered)); // Parse as float
          arrayReceived.push(formatDate(item.ReceivedDate)); // Format date
        }
      });

      setChartUnits(arrayUnitsOrdered);
      setChartReceivedTime(arrayReceived);
    } else {
      setChartUnits([]);
      setChartReceivedTime([]);
    }
  }, [itemList]);

  return (
    <div className="flex flex-col justify-center items-center h-3/4 w-3/4 md:w-44 md:h-36">
      <div className="md:w-52 md:h-52">
        <Bar
          data={{
            labels: chartReceivedTime,
            datasets: [
              {
                label: "Units Ordered vs Time Chart",
                backgroundColor: "#334155",
                borderColor: "#334155",
                data: chartUnits,
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default BarChart;
