import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";
import axios from "axios";

const Selectitem = ({ token, setToken, selectedItem, setSelectedItem }) => {
  let navigate = useNavigate();
  const Url = process.env.REACT_APP_BASE_URL;

  // const url =
  //   "https://backend.thecorefood.silverlinepos.com/purchaseitems-list"; // dynamic url needed
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    let tokenCheck = localStorage.getItem("token");
    if (!tokenCheck) {
      navigate("/");
    } else {
      setToken(localStorage.getItem("token"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      axios
        .post(`${Url}/purchaseitems-list`, {
          token: token,
        })
        .then((response) => {
          // Assuming the response is in the format provided by you
          const itemOptions = response.data.itemslist.map((item) => ({
            value: item,
            name: item,
          }));
          setItemsList(itemOptions);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (itemsList.length > 0) {
      setSelectedItem();
    }
  }, [itemsList]);

  return (
    <div className="select-search">
      <SelectSearch
        defaultValue={selectedItem}
        search
        placeholder={"Select Item"}
        onChange={(event) => setSelectedItem(event)}
        options={itemsList}
      />
    </div>
  );
};

export default Selectitem;
