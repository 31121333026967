import React, { useEffect, useState } from "react";
import { Line, Pie } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import Navbar from "../Navbar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Url = process.env.REACT_APP_BASE_URL;

const ItemData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalrevenue, setTotalRevenue] = useState("");
  const [totalcount, setTotalCount] = useState("");
  const [groupranking, setGroupRanking] = useState("");

  const fetchData = async () => {
    const payload = {
      token: localStorage.getItem("token") || "test",
      outlet: localStorage.getItem("outlet"),
      description: localStorage.getItem("group"),
      name: localStorage.getItem("item"),
    };

    try {
      const response = await axios.post(`${Url}/give_itemdetails`, payload);
      setData(response.data);
      setLoading(false);
      setTotalCount(response.data.Total_Count);
      setTotalRevenue(response.data.Total_Revenue);
      setGroupRanking(response.data.Group_Ranking);
    } catch (err) {
      setError("Error fetching data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const lastMonthData = {
    labels: data.Last_Monthly.map(
      (item) => `${item.start_date} to ${item.end_date} (${item.week})`
    ),
    datasets: [
      {
        label: "Total Count",
        data: data.Last_Monthly.map((item) => item.total_count),
        fill: false,
        borderColor: "rgba(75,192,192,1)",
        tension: 0.1,
      },
    ],
  };

  const yearlySalesData = {
    labels: data.Yearly_sales_json.map((item) => item.monthName),
    datasets: [
      {
        label: "Total Count",
        data: data.Yearly_sales_json.map((item) => parseFloat(item.TotalCount)),
        fill: false,
        borderColor: "rgba(153,102,255,1)",
        tension: 0.1,
      },
    ],
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const itemRanksData = {
    labels: data.Group_AllItem_Ranks.map((item) => item.ItemName),
    datasets: [
      {
        label: "Item Count",
        data: data.Group_AllItem_Ranks.map((item) => item.Count),
        backgroundColor: data.Group_AllItem_Ranks.map(() => getRandomColor()),
        borderColor: data.Group_AllItem_Ranks.map(() => getRandomColor()),
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true, // Tooltips can be enabled or disabled
      },
      legend: {
        position: "top", // Customize legend positioning as needed
      },
    },
  };

  return (
    <>
      <Navbar />
      <div className="flex flex-col justify-center text-center w-screen mt-2">
        <h2>
          {localStorage.getItem("type")}-{localStorage.getItem("group")}-
          {localStorage.getItem("item")}
        </h2>
        <p className="font-bold">Total Revenue: {totalrevenue}</p>
        <p className="font-bold">Total Count: {totalcount}</p>
        <p className="font-bold">Group Ranking: {groupranking}</p>
      </div>
      <div style={{ width: "65%", margin: "auto" }}>
        <div style={{ marginBottom: "30px", height: "300px" }}>
          <h4 className="mt-5">Weekly Count</h4>
          <Line data={lastMonthData} options={chartOptions} />
        </div>
        <div style={{ marginBottom: "30px", height: "300px" }}>
          <h4 className="mt-5">Yearly Count</h4>
          <Line data={yearlySalesData} options={chartOptions} />
        </div>
        <div className="flex justify-center">
          <div className="w-3/4" style={{ height: "500px" }}>
            <h4 className="mt-5">Item Count</h4>
            <Pie data={itemRanksData} options={chartOptions} />
          </div>

          <div className="flex flex-col mt-5">
            {/* Item Section */}
            <div className="flex flex-col">
              <div className="table-responsive-food">
                <div className="table-responsive">
                  <table className="table-food">
                    <thead className="table-background">
                      <tr className="sticky-header bg-white">
                        <th>Item Name</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.Group_AllItem_Ranks?.length > 0 ? (
                        data.Group_AllItem_Ranks.map((item, index) => (
                          <tr key={index}>
                            <td>{item.ItemName}</td>
                            <td>{item.Count}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemData;
