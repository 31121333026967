import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Navbar from "../Navbar";
import SelectSearchInput from "../SelectSearch";
import { DownloadTableExcel } from "react-export-table-to-excel";

const Statement = () => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState("");
  const [token, setToken] = useState("");
  const [outletSelected, setOutletSelected] = useState("Feels, Jhamsikhel");
  const [error, setErrorMsg] = useState("");
  const tableRef = useRef(null);
  const [status, setStatus] = useState(false);

  const changeOutlet = (e) => {
    setOutletSelected(e);
  };

  const Url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  // Fetch data on initial render
  useEffect(() => {
    if (token && outletSelected) {
      setErrorMsg(""); // Reset error before fetching
      const fetchData = async () => {
        try {
          // Fetch stock data
          const response = await axios.post(`${Url}/getstocksbygroup`, {
            token: token,
            outlet_name: outletSelected,
          });
          setData(response.data);

          // Fetch the latest sync date
          const res = await axios.post(`${Url}/getlatestsyncdate`, {
            token: token,
            outlet_name: outletSelected,
          });
          setDate(res.data.last_synced);
        } catch (error) {
          console.error("Error fetching data", error);
          if (error.response && error.response.data) {
            setErrorMsg(
              error.response.data.message || "No stock records found."
            );
          } else {
            setErrorMsg("An Error Occurred.");
          }
        }
      };
      fetchData();
    }
  }, [token, outletSelected]); // Fetch when token or outlet changes

  const handleExport = () => {
    setStatus(true);
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          margin: "20px",
          width: "60%",
          marginLeft: "auto",
          marginRight: "auto",
        }}>
        {/* <h2 style={{ textAlign: "center", textDecoration: "underline" }}>
          Core Food And Beverage P. Ltd.
        </h2> */}
        <p style={{ textAlign: "center" }}>
          <div className="flex justify-center text-center">
            <SelectSearchInput
              token={token}
              setToken={setToken}
              selectedOutlet={outletSelected}
              setSelectedOutlet={changeOutlet}
            />
          </div>
        </p>
        {/* {decodeURIComponent(outletSelected) ? (
          <h1 className="text-lg text-center font-medium font-poppins md:text-xl">
            {decodeURIComponent(outletSelected || "") || ""}
          </h1>
        ) : (
          <h1 className="text-lg text-center font-medium font-poppins md:text-xl">
            {decodeURIComponent(outletSelected || "") || ""}
          </h1>
        )} */}
        <DownloadTableExcel
          filename="statement"
          sheet="Outlet"
          currentTableRef={tableRef.current}>
          <button className="export" onClick={handleExport}>
            {" "}
            Export{" "}
          </button>
        </DownloadTableExcel>
        {/* <p style={{ textAlign: "center", textDecoration: "underline" }}>
          Stock Statement Report For: {date}
        </p> */}

        {/* Show error message if there's an error */}
        {error ? (
          <p style={{ textAlign: "center", color: "red" }}>{error}</p>
        ) : (
          <div ref={tableRef}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
              }}>
              <thead>
                <th colSpan={7}>
                  {/* {status && ( */}
                  <div className="flex flex-col justify-end">
                    <h1
                      style={{
                        textAlign: "center",
                        textDecoration: "underline",
                      }}>
                      Core Food And Beverage P. Ltd.
                    </h1>

                    {decodeURIComponent(outletSelected) ? (
                      <h1
                        style={{
                          textAlign: "center",
                        }}
                        className="text-lg text-center font-medium font-poppins md:text-xl">
                        {decodeURIComponent(outletSelected || "") || ""}
                      </h1>
                    ) : (
                      <h1
                        style={{
                          textAlign: "center",
                        }}
                        className="text-lg text-center font-medium font-poppins md:text-xl">
                        {decodeURIComponent(outletSelected || "") || ""}
                      </h1>
                    )}

                    <p
                      style={{
                        textAlign: "center",
                        textDecoration: "underline",
                      }}>
                      Stock Statement Report For: {date}
                    </p>
                  </div>
                  {/* )} */}
                </th>
                <tr>
                  <th style={headerStyle}>Group Name</th>
                  <th style={headerStyle}>Item Name</th>
                  <th style={headerStyle}>Brand Name</th>
                  <th style={headerStyle}>UOM</th>
                  <th style={headerStyle}>Current Level</th>
                  <th style={headerStyle}>Rate</th>
                  <th style={headerStyle}>Total</th>
                </tr>
              </thead>
              <tbody>
                {data.map((group, groupIndex) => (
                  <React.Fragment key={groupIndex}>
                    <tr>
                      <td
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          padding: "10px 5px",
                          borderBottom: "2px solid #010203",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                        colSpan="1">
                        {group.group}
                      </td>
                    </tr>
                    {group.stocks.map((item, itemIndex) => (
                      <tr key={itemIndex}>
                        <td style={emptyCellStyle}></td>
                        <td style={cellStyle}>{item.ItemName}</td>
                        <td style={cellStyle}>{item.BrandName}</td>
                        <td style={cellStyle}>{item.UOM}</td>
                        <td style={cellStyle}>{item.CurrentLevel}</td>
                        <td style={cellStyle}>{item.Rate}</td>
                        <td style={cellStyle}>{item.Total}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6" style={emptyCellStyle}></td>
                      <td style={{ ...cellStyle, fontWeight: "bold" }}>
                        {group.group_total.toFixed(2)}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
                <tr>
                  <td colSpan="6" style={emptyCellStyle}></td>
                  <td style={{ ...cellStyle, fontWeight: "bold" }}>
                    {data
                      .reduce((acc, group) => acc + group.group_total, 0)
                      .toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

const headerStyle = {
  padding: "5px",
  textAlign: "center",
  fontWeight: "bold",
  textDecoration: "underline",
  fontSize: "17px",
};

const cellStyle = {
  padding: "1px 1px",
  textAlign: "center",
  fontSize: "14px",
};

const emptyCellStyle = {
  padding: "1px 1px",
  textAlign: "center",
};

export default Statement;
