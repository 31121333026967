import axios from "axios";
import React, { useEffect, useState } from "react";

const PurchaseCountTableinfo = ({ PurchaseCountTableinfo }) => {
  // const [PurchaseCountTableinfo, setPurchaseCountTableinfo] = useState([]);
  // const Url = process.env.REACT_APP_BASE_URL;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(`${Url}/salesvspurchase`, {
  //         token: "test",
  //       });
  //       // Set the purchase item data from the API response
  //       setPurchaseCountTableinfo(response.data.purchaseitembycount_responseJson || []);
  //     } catch (error) {
  //       console.error("Error fetching purchase item data:", error);
  //       setPurchaseCountTableinfo([]); // In case of an error, set PurchaseCountTableinfo to an empty array
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <div className="table-responsive-food -z-0">
      <table className="table-food">
        <thead className="table-background ">
          <tr className="sticky-header bg-white">
            <th>Item</th>
            <th>Count</th>
          </tr>
        </thead>
        {/* Check if PurchaseCountTableinfo is not empty and map over it */}
        {PurchaseCountTableinfo.length > 0 ? (
          PurchaseCountTableinfo.map((item, index) => (
            <tr key={index}>
              <td>{item.Name}</td>
              <td>{item.purchase_count}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="2">No data available</td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default PurchaseCountTableinfo;
