import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import {
  useNavigate,
  useLocation,
  generatePath,
  useSearchParams,
  Outlet,
} from "react-router-dom";
import Hometable from "../Table/homeTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CollapsableButton from "./collapsableButton";
import Navbar from "../Navbar";
import SelectSearchInput from "./../SelectSearch/index";
import Footer from "../Footer";
import Inventorydetailtable from "./Inventorydetailtable";
import { Bar, Line } from "react-chartjs-2";
import PurchaseVolTable from "./PurchaseVolTable";
import PurchaseCountTable from "./PurchaseCountTable";
import TopItem from "./TopItem";
import TopVendor from "./TopVendor";

// import { useDispatch, useSelector } from "react-redux";
// import { callApi } from "../actions";
// import SelectSearchInput from './../SelectSearch/index';

export const storeContext = createContext();

const Url = process.env.REACT_APP_BASE_URL;

const Front = () => {
  // const state = useSelector(state => state.Home)
  // let dispatch = useDispatch();
  const [token, setToken] = useState("");
  const [outletSelected, setOutletSelected] = useState("");
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [dateToday, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchParams, setSearchParams] = useSearchParams();
  const [info, setInfo] = useState([]);
  const [itemdata, setItemData] = useState([]);
  const [ItemSelected, setItemSelected] = useState(null);
  const [infostatus, setInfoStatus] = useState(false);
  const [newdata, setNewData] = useState([]);
  const [purchasedata, setPurchaseData] = useState([]);
  const [TopIteminfo, setTopItem] = useState([]);
  const [TopVendorinfo, setTopVendor] = useState([]);
  const [PurchaseCountTableinfo, setPurchaseCountTable] = useState([]);
  const [PurchaseVolTableinfo, setPurchaseVolTable] = useState([]);
  const [salesdata, setSalesData] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState("Feels, Jhamsikhel");
  const [Company_Name, setVendor_Name] = useState("");
  const [vendorchart, setVendorChart] = useState([]);
  // const [GRN, setGRN] = useState();
  // const [ID, setItemId] = useState([]);

  const location = useLocation();
  // const locationHash = location.hash;
  // var str = locationHash.substring(1);
  // let outlet = decodeURI(str)
  useEffect(() => {
    let tokenCheck = localStorage.getItem("token");

    if (tokenCheck === null) {
      navigate("/");
    } else {
      setToken(localStorage.getItem("token"));
    }
    setSelectedOutlet(localStorage.getItem("outlet"));
    // const out = axios.post(`${Url}/outlets`, {
    //   token: "test",
    // });
    // setSelectedOutlet(out.data[0].name);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.post(`${Url}/salesvspurchase`, {
          token: token || "test",
          outlet: selectedOutlet,
        });
        const res = await axios.get(
          `${Url}/reqfilterfirst/?secondtime=${end}&firsttime=${start}&outlet_name=${selectedOutlet}`
        );
        setInfo(res.data.purchase_items_data);
        setVendorChart(res.data.vendorwise_totals);
        //--------------------------------------------
        setTopItem(resp.data.toppurchasingvendors_bycount);
        setTopVendor(resp.data.toppurchasingvendors_byvolume);
        setPurchaseCountTable(resp.data.purchaseitembycount_responseJson);
        setPurchaseVolTable(resp.data.purchaseitembyvolume_responseJson);

        // const out = await axios.get(`${Url}/outlets`);
        // setOutletSelected(out.data[0].name);
        // setItemId(res.data.purchaserequisition.IDIntbl_PurchaseRequisition);
        // purchaserequisition
        // Extract monthly sales and purchases from API response
        const monthlyPurchases = resp.data.monthlyPurchases;
        const monthlySales = resp.data.monthlySales;

        // Set the fetched data into the state variables
        setNewData(resp.data);
        setPurchaseData(monthlyPurchases);
        setSalesData(monthlySales);
      } catch (error) {
        console.log("Error fetching sales vs purchase data:", error);
      }
    };

    fetchData();
  }, [selectedOutlet]);
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const res = await axios.get(
          `${Url}/reqfilterfirst/?secondtime=${end}&firsttime=${start}&outlet_name=${selectedOutlet}`
        );
        setInfo(res.data.purchase_items_data);
        // const out = await axios.get(`${Url}/outlets`);
        // setOutletSelected(out.data[0].name);
        // setItemId(res.data.purchaserequisition.IDIntbl_PurchaseRequisition);
        // purchaserequisition
        // Extract monthly sales and purchases from API response
        // Set the fetched data into the state variables
        setData(res.data.purchaserequisition);
        // setGRN(localStorage.setItem(result.data.purchaserequisition.GRN));
        setSearchParams({
          firsttime: start,
          secondtime: end,
          outletName: searchOutletName,
        });
        setSearchParams({
          firsttime: start,
          secondtime: end,
          outletName: selectedOutlet,
        });
      } catch (error) {
        console.log("Error fetching sales vs purchase data:", error);
      }
    };

    fetchData2();
  }, []);

  useEffect(() => {
    getHome(dateToday, selectedOutlet, outletSelected);
  }, [dateToday]);

  // const showHome = () => {
  //   dispatch(
  //     callApi()
  //   )
  // }
  let start = startDate.toISOString().slice(0, 10);
  let end = endDate.toISOString().slice(0, 10);

  // useEffect(() => {
  //   localStorage.setItem('outlet-name', locationHash)
  // }, [start, end])

  let navigate = useNavigate();
  let path = "/detail/:start/:end";

  const goDetail = () => {
    const pathName = generatePath(path, { start, end });
    navigate(pathName);
  };

  const handleDate = async () => {
    try {
      if (searchOutletName) {
        const result = await axios.get(
          `${Url}/reqfilterfirst/?secondtime=${end}&firsttime=${start}&outlet_name=${searchOutletName}`
        );
        setData(result.data.purchaserequisition);
        setVendorChart(result.data.vendorwise_totals);
        // setGRN(localStorage.setItem(result.data.purchaserequisition.GRN));
        setSearchParams({
          firsttime: start,
          secondtime: end,
          outletName: searchOutletName,
        });
      } else {
        const result = await axios.get(
          `${Url}/reqfilterfirst/?secondtime=${end}&firsttime=${start}&outlet_name=${selectedOutlet}`
        );
        setData(result.data.purchaserequisition);
        setVendorChart(result.data.vendorwise_totals);
        setSearchParams({
          firsttime: start,
          secondtime: end,
          outletName: selectedOutlet,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInfo = async () => {
    setInfoStatus(true);
    try {
      const res = await axios.get(
        `${Url}/reqfilterfirst/?secondtime=${end}&firsttime=${start}&outlet_name=${selectedOutlet}`
      );
      setInfo(res.data.purchase_items_data);
      setVendorChart(res.data.vendorwise_totals);
    } catch (error) {
      console.log(error);
    }
  };
  const handleReset = () => {
    setSearchParams({});
  };

  let searchFirstDate = searchParams.get("firsttime");
  let searchSecondDate = searchParams.get("secondtime");
  let searchOutletName = searchParams.get("outletName");

  const getHome = async (dateToday, selectedOutlet, outletSelected) => {
    try {
      if (location.search !== "") {
        const result = await axios.get(
          `${Url}/reqfilterfirst/?secondtime=${searchSecondDate}&firsttime=${searchFirstDate}&outlet_name=${searchOutletName}`
        );
        setData(result.data.purchaserequisition);
      }
      // else {
      //   const result = await axios.get(
      //     `${Url}/reqfilterfirst/?secondtime=${dateToday}&firsttime=${dateToday}&outlet_name=${selectedOutlet}`
      //   );
      //   setData(result.data.purchaserequisition);
      // }
    } catch (error) {
      console.log(error);
    }
  };
  const handleStatement = async () => {
    navigate("/statement");
  };
  const handleCostCenter = async () => {
    navigate("/costcenter");
  };
  const handleItem = async () => {
    try {
      // Convert startDate and endDate to YYYY-MM-DD format (to match API requirements)
      const startFormatted = startDate.toISOString().slice(0, 10);
      const endFormatted = endDate.toISOString().slice(0, 10);

      // Make the API request with dynamic values
      const result = await axios.post(
        `${Url}/purchasefiltervendorwisefrompurchaseitem`,
        {
          token: token, // Use the token state instead of hardcoded "test"
          item: ItemSelected, // Use ItemSelected directly
          dateStart: startFormatted, // Start date in YYYY-MM-DD format
          dateEnd: endFormatted, // End date in YYYY-MM-DD format
        }
      );

      // Ensure the response has valid data
      if (result && result.data && result.data.data) {
        const itemData = result.data.data; // Set the fetched data into itemdata

        // Navigate to '/items' page and pass the item data as state
        navigate("/items", { state: { itemdata: itemData } });
      } else {
        setItemData([]); // Handle the case where no data is returned
      }
    } catch (error) {
      console.log(error); // Handle error
      setItemData([]); // Reset itemdata on error
    }
  };

  const viewReport = async () => {
    try {
      // if(searchOutletName){
      //   const response = await axios.get(`${Url}api/reqfilter/?time=${searchFirstDate}&time2=${searchSecondDate}&company_name=${inputValue}`)
      //   console.log(response.data.purchaserequisition, "searchOutletName")
      //   setData(response.data.purchaserequisition);
      // }
      const response = await axios.get(
        `${Url}/reqfilter/?time=${start}&time2=${end}&company_name=${inputValue}`
      );

      setData(response.data.purchaserequisition);
      setInfo(response.data.purchase_items_data);
      setVendor_Name(response.data.purchaserequisition[0].Company_Name);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (element) => {
    const {
      IDIntbl_PurchaseRequisition,
      Date,
      Company_Name,
      Outlet_Name,
      State,
      purchaseBillNumber,
      DiscountAmount,
      TaxAmount,
      TotalAmount,
      GRN,
    } = element;
    localStorage.setItem("ID", IDIntbl_PurchaseRequisition);
    localStorage.setItem("date", Date);
    localStorage.setItem("company name", Company_Name);
    localStorage.setItem("outlet name", Outlet_Name);
    localStorage.setItem("state", State);
    localStorage.setItem("bill num", purchaseBillNumber);
    localStorage.setItem("discount", DiscountAmount);
    localStorage.setItem("tax", TaxAmount);
    localStorage.setItem("total", TotalAmount);
    localStorage.setItem("GRN", GRN);
    navigate("/detail");
  };

  let length = data.length;

  function sumArray(array) {
    let sum = 0;
    array.forEach((item) => {
      sum += parseInt(item.TaxAmount);
    });
    return sum;
  }

  function sumTotalAmount(array) {
    let sumTotal = 0;
    array.forEach((item) => {
      sumTotal += parseInt(item.TotalAmount);
    });
    return sumTotal;
  }
  let totalTax = sumArray(data);
  let total = sumTotalAmount(data);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const changeOutlet = (e) => {
    setSelectedOutlet(encodeURIComponent(e));
    getHome(dateToday, encodeURIComponent(e));
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("Key pressed: ", event.key);
      viewReport();
    }
  };
  // const reversedItemData = newdata.slice().reverse(); //api is different
  const chartData = {
    labels: purchasedata.map((item) => item.monthName), // Extract month names for labels
    datasets: [
      {
        label: "Sales vs Time Chart",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#94a3b8",
        data: salesdata.map((item) => parseFloat(item.Total)), // Convert 'Total' to float
      },
      {
        label: "Purchase vs Time Chart",
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "#4b6cb7",
        data: purchasedata.map((item) => parseFloat(item.Total)), // Convert 'Total' to float
      },
    ],
  };
  const chartData2 = {
    labels: vendorchart.map((item) => item.vendor), // Extract month names for labels
    datasets: [
      {
        label: "Sales vs Vendor",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#94a3b8",
        data: vendorchart.map((item) => parseFloat(item.total)), // Convert 'Total' to float
      },
    ],
  };

  return (
    <div>
      <Navbar />
      <div className="h-screen flex flex-col p-4">
        {/* {decodeURIComponent(searchOutletName) ? (
          <h1 className="text-lg text-center font-medium font-poppins md:text-xl">
            {decodeURIComponent(searchOutletName || "") || ""}
          </h1>
        ) : (
          <h1 className="text-lg text-center font-medium font-poppins md:text-xl">
            {decodeURIComponent(outletSelected || "") || ""}
          </h1>
        )} */}
        {decodeURIComponent(selectedOutlet) ? (
          <h1 className="text-lg text-center font-medium font-poppins md:text-xl">
            {decodeURIComponent(selectedOutlet || "") || ""}
          </h1>
        ) : (
          <h1 className="text-lg text-center font-medium font-poppins md:text-xl">
            {decodeURIComponent(searchOutletName || "") || ""}
          </h1>
        )}
        <div className="flex justify-between">
          <div className="text-center">
            <SelectSearchInput
              token={token}
              setToken={setToken}
              setSelectedOutlet={setSelectedOutlet}
              selectedOutlet={selectedOutlet}
            />
            {/* <SelectSearchInput
              token={token}
              setToken={setToken}
              selectedOutlet={outletSelected}
              setSelectedOutlet={changeOutlet}
            /> */}
          </div>
          <div className="flex flex-col">
            <button
              className=" w-20 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg  md:h-9"
              onClick={handleStatement}>
              View Statement
            </button>
            <button
              className=" w-20 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg  md:h-9"
              onClick={handleCostCenter}>
              Cost Center
            </button>
            <button
              className=" w-20 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg  md:h-9"
              onClick={handleItem}>
              Item History
            </button>
          </div>
        </div>

        <CollapsableButton
          DatePicker={DatePicker}
          dateToday={dateToday}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleDate={handleDate}
          inputValue={inputValue}
          handleInputChange={handleInputChange}
          viewReport={viewReport}
        />
        <div className="hidden md:flex border-2 border-slate-500 w-full justify-between my-4 mx-2 ">
          <div className="flex items-center md:flex-row">
            <div className="w-full flex flex-between flex-col m-2">
              <label className="font-medium font-poppins md:text-base text-center">
                Date Selection
              </label>
              <div className="flex w-full">
                <div>
                  <label className="font-base font-poppins md:text-base">
                    From:
                  </label>
                  <DatePicker
                    selected={startDate}
                    dateFromat="YYYY-MM-DD"
                    onChange={(date) => setStartDate(date)}
                    className="w-16 md:w-24 text-xs md:text-base cursor-pointer"
                  />
                </div>
                <div>
                  <label className="font-base font-poppins md:text-base">
                    To:
                  </label>
                  <DatePicker
                    selected={endDate}
                    dateFromat="yyyy-mm-dd"
                    onChange={(date) => setEndDate(date)}
                    className="w-16 md:w-24 text-xs md:text-base cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="my-4">
                <button
                  onClick={() => {
                    handleDate();
                    handleInfo();
                  }}
                  className="w-12 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg md:h-7 md:w-14 md:text-xs">
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-col justify-center">
            <h4 className="font-medium font-poppins md:text-base text-center">
              Purchase Entry:
            </h4>
            <div className="flex">
              <p className="p-2 mt-2">
                From: <span>{dateToday}</span>
              </p>
              <p className="p-2">
                To: <span>{dateToday}</span>
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="md:my-2">
              <label className="px-2 font-medium font-poppins md:text-base text-center">
                Search:
              </label>
              <input
                type="text"
                className="border-2 border-slate-400 rounded-lg h-5 md:h-8 text-xs md:text-base placeholder:text-center placeholder:italic"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Vendor"
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="px-2">
              <button
                onClick={viewReport}
                className="w-20 h-7 text-xs border-2 border-slate-300 hover:bg-slate-100 bg-slate-700 text-zinc-100 hover:text-zinc-700 rounded-lg  md:h-9">
                View Reports
              </button>
            </div>
          </div>
        </div>
        {data.length > 0 ? (
          <Hometable
            handleChange={handleChange}
            data={data}
            props={outletSelected}
            length={data.length}
            totalTax={totalTax}
            total={total}
            // ID={ID}
            // GRN={GRN}
          />
        ) : (
          <table className="table-auto w-full overflow-auto rounded-2xl text-center relative font-poppins text-sm md:text-base">
            <thead className="md:h-10 lg:sticky lg:top-0 bg-slate-50">
              <tr className="mt-4 p-4 border-b-2 border-slate-400 md:my-5">
                <th>S.N</th>
                <th>Vendor</th>
                <th>Ordered Date</th>
                <th>Received Date</th>
                <th>Tax</th>
                <th>Total</th>
                <th>State</th>
                <th>GRN</th>
                <th>Purchase Order ID</th>
                <th className="text-green-500">View</th>
              </tr>
            </thead>
            <tr>
              <td colSpan="10">No data available!</td>
            </tr>
          </table>
        )}
        {info.length > 0 ? (
          <div className="flex flex-col">
            <div className="w-full min-h-[400px] md:h-[90vh] overflow-y-scroll overflow-x-auto hover:shadow mt-2">
              <Inventorydetailtable info={info} Company_Name={Company_Name} />
            </div>
          </div>
        ) : (
          <table className="table-auto w-full bg-opacity-75 overflow-auto rounded-2xl text-center relative font-poppins text-sm md:text-base">
            <thead className=" md:h-10 lg:sticky lg:top-0 bg-slate-50">
              <tr className="mt-4 p-4 border-b-2 border-slate-400 md:my-5">
                <th>S.N</th>
                <th>Name</th>
                <th>Group Name</th>
                <th>Vendor Name</th>
                <th>Unit</th>
                <th>UOM</th>
                <th>Rate</th>
                <th>Total</th>
                <th>Tax</th>
              </tr>
            </thead>
            <tr>
              <td colSpan="9">No data available!</td>
            </tr>
          </table>
        )}

        <div className="flex justify-evenly mb-5 mt-10">
          <div className="flex flex-col">
            <p className="flex justify-center font-serif">Top Item by Volume</p>
            <PurchaseVolTable PurchaseVolTableinfo={PurchaseVolTableinfo} />
          </div>
          <div className="flex flex-col">
            <p className="flex justify-center font-serif">Top Item by Count</p>
            <PurchaseCountTable
              PurchaseCountTableinfo={PurchaseCountTableinfo}
            />
          </div>

          <div className="flex flex-col">
            <p className="flex justify-center font-serif">
              Top Vendor by Volume
            </p>
            <TopVendor TopVendorinfo={TopVendorinfo} />
          </div>
          <div className="flex flex-col">
            <p className="flex justify-center font-serif">
              Top Vendor by Count
            </p>
            <TopItem TopIteminfo={TopIteminfo} />
          </div>
        </div>
        <div className="flex justify-center">
          <div className=" w-1/2 mt-10 ">
            <Bar data={chartData2} />
          </div>
          <div className=" w-1/2 mt-10 ">
            <Line data={chartData} />
          </div>
        </div>
        <div className="flex justify-center">
          <div className=" w-1/2 mt-32 ">
            <div className="table-responsive-food">
              <table className="table-food">
                <thead className="table-background">
                  <tr className="sticky-header bg-white">
                    <th>Vendor</th>
                    <th>Sales</th>
                  </tr>
                </thead>
                {/* Check if itemdata is not empty */}
                {vendorchart.length > 0 ? (
                  vendorchart.map((item, index) => (
                    <tr key={index}>
                      <td>{item.vendor}</td>
                      <td>{item.total}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No data available</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
          <div className="w-1/2 flex">
            <div className=" w-1/2 mt-32 ">
              <div className="table-responsive-food">
                <table className="table-food">
                  <thead className="table-background">
                    <tr className="sticky-header bg-white">
                      <th>Month</th>
                      <th>Purchase</th>
                    </tr>
                  </thead>
                  {/* Check if itemdata is not empty */}
                  {purchasedata.length > 0 ? (
                    purchasedata.map((item, index) => (
                      <tr key={index}>
                        <td>{item.monthName}</td>
                        <td>{item.Total}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No data available</td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
            <div className=" w-1/2 mt-32 ">
              <div className="table-responsive-food">
                <table className="table-food">
                  <thead className="table-background">
                    <tr className="sticky-header bg-white">
                      <th>Month</th>
                      <th>Sales</th>
                    </tr>
                  </thead>
                  {/* Check if itemdata is not empty */}
                  {salesdata.length > 0 ? (
                    salesdata.map((item, index) => (
                      <tr key={index}>
                        <td>{item.monthName}</td>
                        <td>{item.Total}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No data available</td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Front;
