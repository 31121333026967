import axios from "axios";
import React, { useEffect, useState } from "react";

const TopIteminfo = ({ TopIteminfo }) => {
  // const [itemdata, setItemData] = useState([]);
  // const Url = process.env.REACT_APP_BASE_URL;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(`${Url}/salesvspurchase`, {
  //         token: localStorage.getItem("token"), // Retrieve token from localStorage
  //       });

  //       // Set the response data to state, ensure it exists
  //       setItemData(response.data.toppurchasingvendors_bycount || []);
  //     } catch (error) {
  //       console.error("Error fetching top purchasing vendors:", error);
  //       setItemData([]); // In case of error, set to an empty array
  //     }
  //   };

  //   fetchData();
  // }, []); // Empty dependency array to run only once on component mount

  return (
    <div className="table-responsive-food">
      <table className="table-food">
        <thead className="table-background">
          <tr className="sticky-header bg-white">
            <th>Vendor</th>
            <th>Count</th>
          </tr>
        </thead>
        {/* Check if itemdata is not empty */}
        {TopIteminfo.length > 0 ? (
          TopIteminfo.map((item, index) => (
            <tr key={index}>
              <td>{item.Company_Name}</td>
              <td>{item.purchaseCount}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="2">No data available</td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default TopIteminfo;
