import React from "react";

const Hometable = ({ data, handleChange, length, totalTax, total }) => {
  return (
    <div className="w-full min-h-[400px] md:h-[90vh] overflow-y-scroll overflow-x-auto hover:shadow mt-2 relative">
      <table className="table-auto w-full overflow-auto rounded-2xl text-center relative font-poppins text-sm md:text-base">
        {/* bg-opacity-75 */}
        <thead className="md:h-10 lg:sticky lg:top-0 bg-slate-50">
          <tr className="mt-4 p-4 border-b-2 border-slate-400 md:my-5">
            <th>S.N</th>
            <th>Vendor</th>
            <th>Ordered Date</th>
            <th>Received Date</th>
            <th>Tax</th>
            <th>Total</th>
            <th>State</th>
            <th>GRN</th>
            <th>Purchase Order ID</th>
            <th className="text-green-500">View</th>
          </tr>
        </thead>
        <tbody className="m-1 h-auto">
          {data.map((element, index) => (
            <tr className="h-auto" key={index}>
              <td>{index + 1}</td>
              <td>{element.Company_Name}</td>
              <td>
                {new Date(element.Date).toLocaleDateString("en-GB", {
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </td>
              <td>
                {new Date(element.ReceivedDate).toLocaleDateString("en-GB", {
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </td>
              <td>{element.TaxAmount}</td>
              <td>{element.TotalAmount}</td>
              <td className="text-green-800">{element.State}</td>
              <td>{element.GRN} </td>
              <td>{element.IDIntbl_PurchaseRequisition}</td>
              <td>
                <button
                  className="border-2 border-green-600 rounded-lg w-16 h-8 hover:bg-green-600 hover:text-gray-200"
                  onClick={() => {
                    handleChange(element);
                  }}>
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        {/* Footer row */}
        <tfoot className="w-full mt-1 bg-white p-4 border-t-2 border-slate-400 sticky bottom-0 -z-0">
          <tr className="p-4 border-t-2 border-slate-400 h-14">
            <th>
              Count: <span className="text-base">{length}</span>
            </th>
            <th></th>
            <th> </th>
            <th> </th>
            <th>
              Total Tax: <span className="text-base">{totalTax}</span>
            </th>
            <th>
              Total: <span className="text-base">{total}</span>
            </th>
            <th></th>
            <th></th>
            <th> </th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Hometable;
